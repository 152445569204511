import { render, staticRenderFns } from "./LogisticDetail.vue?vue&type=template&id=067ca229&scoped=true"
import script from "./LogisticDetail.vue?vue&type=script&lang=js"
export * from "./LogisticDetail.vue?vue&type=script&lang=js"
import style0 from "./LogisticDetail.vue?vue&type=style&index=0&id=067ca229&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "067ca229",
  null
  
)

export default component.exports